import React, {useEffect} from 'react';
import Backdrop from '@mui/material/Backdrop';
import makeStyles from '@mui/styles/makeStyles';
import './BidmiiCustomLoader.css';
import {useRecoilState, useRecoilValue} from 'recoil';
import {loadingState} from '../../recoil/bidmiiCustomLoader/bidmiiCustomLoaderAtom';
import {tb} from '../../recoil/user/userAtom';

export default function BidmiiCustomLoader(props: $TSFixMe) {
  const [loading, setLoading] = useRecoilState(loadingState);
  const tbvalue = useRecoilValue(tb);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 100000,
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(230, 230, 230, 0.7)',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (tbvalue) {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.loading || loading}>
        <div
          style={{
            display: 'flex',
            marginRight: window.innerWidth > 600 ? 4 : 0,
          }}>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </Backdrop>
    </div>
  );
}
