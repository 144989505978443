import React from 'react';
import Rating from '@mui/material/Rating';
import withStyles from '@mui/styles/withStyles';
import {getOS, whoAmI} from './DeviceMetadataUtils';
import packageInfo from '../../package.json';
import {securedFetchRequest} from '@bidmii/common/lib/util/FetchRequest';
import {API_URL} from '../constants';
import {UserType} from '@bidmii/common/lib/server/IServerApi';
import * as Sentry from '@sentry/react';
import {collection, doc, getDoc, setDoc} from 'firebase/firestore';
import {firestoreDb} from '../App';

const StyledRating = withStyles({
  iconFilled: {
    color: '#22ABF7 !important',
  },
  iconHover: {
    color: '#000000 !important',
  },
})(Rating);

export const getProfileImage = (profile: $TSFixMe, size: $TSFixMe) => {
  if (profile && profile?.imgAttachmentRef !== null) {
    return profile.imgAttachmentRef.url;
  } else {
    return 'https://cdn.bidmii.com/media/web_assets/blank-avatar.png';
  }
};

export const userRating = (user: $TSFixMe) => {
  const rating = user.rating;
  if (rating === 0) {
    return null;
  }

  const ratingValue = (user.rating / 100) * 5;
  return (
    <StyledRating size="small" precision={0.1} value={ratingValue} readOnly />
  );
};

export const postSessionData = () => {
  let browserCoords = {};
  navigator.geolocation.getCurrentPosition(
    (pos) => {
      browserCoords = {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
      };
    },
    (error) => {
      console.warn(error);
    },
    {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    },
  );
  const browserInfo = whoAmI();
  const systemInfo = getOS();

  const body = {
    deviceName: browserInfo,
    osVersion: systemInfo.OS + ' ' + systemInfo.Platform,
    appVersion: packageInfo.version,
    sessionStart: new Date().toISOString(),
    deviceType: 'BROWSER',
    latitude: (browserCoords as $TSFixMe)?.latitude,
    longitude: (browserCoords as $TSFixMe)?.longitude,
    utmSource: localStorage.getItem('utm_source') || '',
    utmMedium: localStorage.getItem('utm_medium') || '',
    utmCampaign: localStorage.getItem('utm_campaign') || '',
    utmTerm: localStorage.getItem('utm_term') || '',
    utmContent: localStorage.getItem('utm_content') || '',
  };

  securedFetchRequest(`${API_URL}sessions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((data) => {
      process.env.NODE_ENV === 'development' && console.log(data);
      if (
        localStorage.getItem('utm_source') !== null &&
        // @ts-expect-error ts-migrate(2551) FIXME: Property 'utm_source' does not exist on type '{ de... Remove this comment to see the full error message
        body.utm_source === localStorage.getItem('utm_source')
      ) {
        localStorage.removeItem('utm_source');
        localStorage.removeItem('utm_medium');
        localStorage.removeItem('utm_campaign');
        localStorage.removeItem('utm_term');
        localStorage.removeItem('utm_content');
      }
    })
    .catch((err) => {
      process.env.NODE_ENV === 'development' && console.log(err);
      Sentry.withScope(function (scope) {
        scope.setTag('section', 'UserUtils.tsx postSessionData()');
        Sentry.captureException(err);
      });
    });
};

export const recordScreenView = (body: $TSFixMe) => {
  securedFetchRequest(`${API_URL}/statistics/pageview`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).catch((err) => {
    process.env.NODE_ENV === 'development' && console.log(err);
    Sentry.withScope(function (scope) {
      scope.setTag('section', 'UserUtils.tsx recordScreenView()');
      Sentry.captureException(err);
    });
  });
};

export const isContractor = (user: $TSFixMe) => {
  return (
    (user && user.userType === UserType.BIDDER) ||
    (localStorage.getItem('userType') &&
      localStorage.getItem('userType') === UserType.BIDDER)
  );
};

export const isHomeowner = (user: $TSFixMe) => {
  return (
    (user && user.userType === UserType.REQUESTER) ||
    (localStorage.getItem('userType') &&
      localStorage.getItem('userType') === UserType.REQUESTER)
  );
};

export const isRealtor = (user: $TSFixMe) => {
  return (
    (user && user.userType === UserType.REALTOR) ||
    (localStorage.getItem('userType') &&
      localStorage.getItem('userType') === UserType.REALTOR)
  );
};

export const setFirestoreDocument = (user: any, details: any) => {
  // Todo - really not sure about this.
  // @ts-ignore
  const userDocRef = doc(collection(firestoreDb, 'users'), user.uid);
  return setDoc(userDocRef, {...details}, {merge: true});
};

export const getFirestoreDocument = (user: any) => {
  // Todo - really not sure about this.
  // @ts-ignore
  const userDocRef = doc(collection(firestoreDb, 'users'), user.uid);
  return getDoc(userDocRef);
};
