import {useState, useRef, useCallback} from 'react';
import Backdrop from '@mui/material/Backdrop';
import animationWarm from '@bidmii/common/src/data/celebrationAnimations/lottieWarm.json';
import animationCold from '@bidmii/common/src/data/celebrationAnimations/lottieCold.json';
import animationRainbow from '@bidmii/common/src/data/celebrationAnimations/lottieRainbow.json';
import {LazyBidmiiLottieWrapper} from '../common/LazyLoader';

interface IProps {
  palette?: 'warm' | 'cold' | 'rainbow';
}

export default function useCelebrationLottie(props: IProps) {
  const lottieRef = useRef<any>(null);
  const [playing, setPlaying] = useState(false);

  const getPalette = {
    warm: animationWarm,
    cold: animationCold,
    rainbow: animationRainbow,
  };

  const startCelebration = useCallback(() => {
    if (!playing) {
      setTimeout(() => {
        setPlaying(true);
        lottieRef.current?.play();
      }, 300);

      setTimeout(() => {
        lottieRef.current?.stop();
        setPlaying(false);
      }, 4000);
    }
  }, [playing]);

  const celebrationAnimation = playing && (
    <Backdrop
      open={playing}
      style={{backgroundColor: 'transparent', zIndex: 19999}}>
      <LazyBidmiiLottieWrapper
        lottieRef={lottieRef}
        animationData={getPalette[props.palette || 'warm']}
      />
    </Backdrop>
  );

  return [celebrationAnimation, startCelebration] as const;
}
