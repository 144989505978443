import React, {useEffect, useState} from 'react';
import {
  fetchRequest,
  securedFetchRequest,
} from '@bidmii/common/lib/util/FetchRequest';
import {API_URL} from '../../constants';
import {LazyStripePaymentFlow} from '../../common/LazyLoader';
import * as Sentry from '@sentry/react';
import {useRecoilValue} from 'recoil';
import {userState} from '../../recoil/user/userAtom';
import {Dialog} from '@mui/material';
import Box from '@mui/material/Box';

let inIframe = false;
try {
  inIframe = window.self !== window.top;
} catch (e) {
  inIframe = true;
}
console.log(`inIframe: ${inIframe}`);

export const ServiceOrderCheckout = (props: $TSFixMe) => {
  const [serviceOrder, setServiceOrder] = React.useState<$TSFixMe>(null);
  const [clientSecret, setClientSecret] = useState<string>();
  const [redirectUrl, setRedirectUrl] = React.useState<string | null>(
    inIframe ? window.location.href : null,
  );
  const user = useRecoilValue(userState);

  const search = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (search.has('redirectUrl')) {
      // @ts-ignore
      setRedirectUrl(search.get('redirectUrl'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  Todo - apply functional support hiding logic here
  useEffect(() => {
    (window as $TSFixMe)._chatlio.hide();

    return () => {
      (window as $TSFixMe)._chatlio.show();
    };
  }, []);
   */

  const getServiceOrderData = () => {
    return fetchRequest(`${API_URL}/service-orders/${props.match.params.uid}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setServiceOrder(res);
      });
  };

  useEffect(() => {
    getServiceOrderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNonceData = () => {
    const body = {
      productIds: 'SERVICE_ORDER',
      recId: serviceOrder.id,
      recModel: 'SERVICE_ORDER',
      redirectUrl: window.location.href,
      productType: 'SERVICE_ORDER',
    };

    const requestFunction = !user ? fetchRequest : securedFetchRequest;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    return requestFunction(`${API_URL}/create-payment-intent`, options)
      .then((response) => response.text())
      .then((CLIENT_TOKEN_FROM_SERVER) => {
        setClientSecret(CLIENT_TOKEN_FROM_SERVER);
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' && console.error(err);
        Sentry.withScope(function (scope) {
          scope.setTag('section', 'StripePaymentFlow.tsx getNonceData()');
          Sentry.captureException(err);
        });
      });
  };

  useEffect(() => {
    if (search.has('redirect_status')) {
      const redirectStatus = search.get('redirect_status');
      const payload = {redirectStatus: redirectStatus};
      window.parent.postMessage(JSON.stringify(payload), '*');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serviceOrder) {
      getNonceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrder]);

  useEffect(() => {
    if (serviceOrder) {
      if (!redirectUrl) {
        setRedirectUrl(serviceOrder._links.service_order_redirect_url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrder]);

  if (!serviceOrder || !clientSecret) {
    return null;
  }

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="md"
      fullScreen={window.innerWidth <= 600}
      PaperProps={{style: {borderRadius: window.innerWidth > 600 ? 10 : 0}}}>
      <Box p={1}>
        <LazyStripePaymentFlow
          redirectUrl={redirectUrl}
          clientSecret={clientSecret}
          key={'payment_flow_service_order'}
          fixedFooter
        />
      </Box>
    </Dialog>
  );
};
