import {IUser} from '@bidmii/common/lib/server/IServerApi';
import {atom, DefaultValue, selector} from 'recoil';
import {setFirebaseUserType} from '../../util/Analytics';
import {localStorageUserState} from '../localStorage/LocalStorageRecoil';

export const userState = atom<IUser>({
  key: 'userState',
  default: localStorageUserState as unknown as IUser, // JK: Hack to ensure type does not contain null as well.
  effects_UNSTABLE: [
    ({onSet}) => {
      onSet((newUser) => {
        if (!(newUser instanceof DefaultValue) && newUser && newUser.userType) {
          setFirebaseUserType(newUser.userType.toLowerCase());
        }
      });
    },
  ],
});

export const userTypeState = selector({
  key: 'userTypeState',
  get: ({get}) => {
    const user = get(userState);
    return user?.userType ?? null; // 'BIDDER' => Contractor, 'REQUESTER' => Home owner
  },
});

export const userStatsState = atom({
  key: 'userStatsState',
  default: false,
});

export const userLikesState = atom({
  key: 'userLikesState',
  default: new Set(),
});

export const userDislikesState = atom({
  key: 'userDislikesState',
  default: new Set(),
});

export const userProductsState = atom({
  key: 'userProductsState',
  default: [],
});

export const sessionUidState = atom({
  key: 'sessionUidState',
  default: null,
});

export const firestoreUserState = atom({
  key: 'firestoreUserState',
  default: null as any,
});

export const miscUserDetailsState = atom({
  key: 'miscUserDetailsState',
  default: [] as any[],
});

export const t = atom({
  key: 't',
  default: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const tb = atom({
  key: 'tb',
  default: false,
});

export const bt = atom({
  key: 'bt',
  default: ['Asia/Gaza'],
});
