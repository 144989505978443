export type BuildEnvironment = 'BETA' | 'PRODUCTION';
interface IApiLink {
  href: string;
}

export interface IPage {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}
export enum UserType {
  REQUESTER = 'REQUESTER',
  BIDDER = 'BIDDER',
  REALTOR = 'REALTOR',
}

export interface IUser extends IUserClientHacks {
  insured: boolean;
  name: string;
  username: string;
  id: number;
  userType: UserType;
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  alias: string | null;
  contractorRating: number | null;
  rating: number;
  title: string | null;
  description: string | null;
  phone: string | null;
  phoneVerified: boolean;
  workPhone: string | null;
  email: string | null;
  billingEmail: string | null;
  workEmail: string | null;
  street: string | null;
  street2: string | null;
  workStreet: string | null;
  workStreet2: string | null;
  city: string | null;
  postal: string | null;
  province: string | null;
  workCity: string | null;
  workPostal: string | null;
  workProvince: string | null;
  country: string | null;
  birthDate: string | null;
  imgAttachmentRef: {url: string} | null;
  reviews: unknown[];
  hasAgreedToTerms: boolean;
  defaultPrivacy: string | null;
  commentPrivacy: string | null;
  sharePrivacy: string | null;
  businessDocuments: unknown[];
  verificationState: string | null;
  taxNo: string | null;
  workWebsite: string | null;
  enabled: boolean;
  lat: string | null;
  lng: string | null;
  tourState: string | null;
  createDate: string | null;
  notes: string | null;
  code: string | null;
  locked: boolean;
  transactions: string | null;
  balance: number;
  trustTransactions: string | null;
  trustBalance: string | null;
  categories: number[];
  totalProposals: string | null;
  wonProposals: string | null;
  fcmDeviceToken: string | null;
  pledgeAcceptedDate: string | null;
  termsAcceptedDate: string | null;
  uid: string | null;
  userSource: string | null;
  myTeamMemberIds: string[];
  referralStatus: string | null;
  categoryNames: string[];
  placeId: string | null;
  fcmDeviceTokenBrowser: string | null;
  online: boolean;
  profileBackgroundImage: {
    id: number;
    name: string | null;
    description: string | null;
    key: string | null;
    firebaseRef: string | null;
    url: string | null;
    type: null;
    expiryDate: string | null;
    resizedUrl: string | null;
  };
  logoImage: {
    id: number;
    name: string | null;
    description: string | null;
    key: string | null;
    firebaseRef: string | null;
    url: string | null;
    type: null;
    expiryDate: string | null;
    resizedUrl: string | null;
  };
  serviceArea: number;
  notificationSettings: {
    uid: string | null;
    timing: string | null;
    emailEnabled: boolean;
    smsEnabled: boolean;
  };
  hasAcceptedPledge: boolean;
  braintreeCustomerId: string | null;
  userVerificationState: string | null;
  siteVisitTemplate: string | null;
  proposalTemplate: string | null;
  contractorCategoryNotificationTimingSelection: string | null;
  domain: string | null;
  color: string | null;
  videoAttachmentRef: any;
  servicePlan: string | null;
  privacy: string | null;
  yearsExperience: number | null;
  socialLinks: string[];
  profileLinks: string[];
  stripeConnectAccountId: string | null;
  stripeCustomerAccountId: string | null;
}

// Interface to represent props that seem to be tagged onto the existing by the client but are not part of the response itself
interface IUserClientHacks {
  initialised: boolean;
}

export interface IUserResponse extends IUser {
  _links: {
    self: IApiLink;
    likes: IApiLink;
    promo: IApiLink;
    notifications: IApiLink;
    conversations: IApiLink;
    stats: IApiLink;
    rfps: IApiLink;
    categories: IApiLink;
    twilioCode: IApiLink;
    phoneNumber: IApiLink;
    badges: IApiLink;
    realtor: IApiLink;
    paymentToken: IApiLink;
    userAccountTransactions: IApiLink;
    share: IApiLink;
    browserDevices: IApiLink;
    team: IApiLink;
  };
}

export interface ISiteVisit {
  uid: string;
  visitDateTime: string | null;
  dateOption1: string | null;
  dateOption2: string | null;
  dateOption3: string | null;
  suggestedDate: string | null;
  state: string;
  description: string | null;
  requestForProposalUID: string | null;
  projectName: string | null;
  province: string | null;
  city: string | null;
  hasBeenRead: boolean;
}
export interface IAttachmentReference {
  description: string | null;
  expiryDate: string | null;
  firebaseRef: string | null;
  id: number | null;
  key: string | null;
  name: string | null;
  resizedUrl: string | null;
  type: string | null;
  url: string | null;
}
export interface IRequestForProposalStats {
  highestQuote: number;
  medianQuote: number;
  lowestQuote: number;
}
export interface IRequestForProposalPublic {
  buyNowPrice: number | null;
  city: string | null;
  createdDate: string | null;
  description: string | null;
  firstName: string | null;
  requesterName: string | null;
  id: number;
  images: {
    imageUrl: string | null;
    originalImageUrl: string | null;
  }[];
  imagesCount: number;
  lastName: string | null;
  likesCount: number | null;
  proposalsCount: number | null;
  province: string | null;
  size: string;
  state: string;
  tags: string[];
  title: string | null;
  type: string | null;
  uid: string;
  userId: number;
  user: IUser | null;
}

export interface ITaxRegion {
  regionCode: string | null;
  title: string | null;
  percent: number;
}

export interface IProposalListItem {
  amount: number;
  city: string | null;
  completionTarget: string | null;
  expiryDate: string | null;
  homeownerAvatar: IAttachmentReference | null;
  id: number;
  proposalDescription: string | null;
  province: string | null;
  requestForProposalState: string | null;
  requesterFirstName: string;
  requesterLastName: string;
  rfpDescription: string | null;
  rfpUid: string | null;
  stagesCount: number;
  state: string | null;
  title: string | null;
  proposedDate: string | null;
  hasBeenViewed: boolean;
}
export interface IProposal {
  addons: any[];
  attachmentReferences: IAttachmentReference[] | null;
  bidExpiry: string | null;
  contingencyAmount: number | null;
  conversationId: number | null;
  createdDate: string | null;
  description: string | null;
  expired: boolean;
  expiryDate: string | null;
  feeAmount: number;
  feeTaxAmount: number;
  feeSubtotalAmount: number | null;
  feeTotalAmount: number | null;
  grandTotal: number | null;
  hasBeenViewed: boolean;
  id: number;
  labourAmount: number | null;
  materialsAmount: number | null;
  netPayoutAmount: number | null;
  note: string | null;
  projectState: string;
  proposedDate: string | null;
  processingFeeAmount: number | null;
  requestForProposal: IRequestForProposalPublic;
  requestForProposalTitle: string | null;
  stages: any[];
  state: string;
  subtotal: number | null;
  surchargeAmount: number | null;
  taxAmount: number;
  taxFactor: number;
  taxRegion: ITaxRegion;
  title: string | null;
  total: number | null;
  type: string | null;
  uid: string | null;
  user: IUser;
  _links: {
    lines: IApiLink;
    stages: IApiLink;
  };
}

export interface IReviewQuestion {
  id: number | null;
  rating: number | null;
  question: string | null;
  sequence: number | null;
}
export interface IRequestForProposalQuestion {}

export interface IReview {
  attachmentReferences: IAttachmentReference[] | null;
  id: number | null;
  note: string | null;
  rating: number | null;
  reviewQuestions: IReviewQuestion[];
  reviewType: string | null;
  reviewed: IUser | null;
  state: string | null;
  title: string | null;
  uid: string | null;
  verified: boolean | null;
}

// Todo- need to find the usecase for this
export interface ICategory {}

export interface IRequestForProposalTag {
  id: number;
  name: string | null;
  type: string | null;
}
export interface IRequestForProposalDetail {
  enabled: boolean;
  id: number | null;
  prompt: string | null;
  type: string | null;
  value: string | null;
}

export interface IRequestForProposalMeta {
  likesCount: number;
  commentsCount: number;
  questionsCount: number;
  proposalsCount: number;
  siteVisitsCount: number;
  proposalsActionCount: number;
  questionsActionCount: number;
  siteVisitsActionCount: number;
  stagesActionCount: number;
  stagesCount: number;
  tourState: string;
}
export interface IRequestForProposalPrivate {
  approvedForCalls: boolean | null;
  attachmentReferences: IAttachmentReference[];
  bidderReview: IReview;
  categoryIds: number[] | null;
  city: string | null;
  completedDate: string | null;
  conversationId: number | null;
  country: number | null;
  createdDate: string | null;
  deadlineDate: string | null;
  description: string | null;
  details: IRequestForProposalDetail[];
  id: number;
  includeMaterialsInQuote: boolean;
  lat: string | null;
  lng: string | null;
  meta: IRequestForProposalMeta;
  postal: string | null;
  postedDate: string | null;
  privacy: string | null;
  promotedExpiryDate: string | null;
  proposal: IProposal | null;
  province: string | null;
  requesterName: string | null;
  requesterReview: IReview;
  size: string;
  state: string;
  street: string | null;
  street2: string | null;
  tags: string[] | null;
  taxRegion: any | null;
  templateId: string | null;
  title: string | null;
  type: string | null;
  uid: string;
  buyNowPrice: number | null;
  user: IUser;
  _links: {
    completion_request: IApiLink;
    invites: IApiLink;
    load_comments: IApiLink;
    load_proposal: IApiLink;
    post_comment: IApiLink;
    post_question: IApiLink;
    questions: IApiLink;
    new_site_visit: IApiLink;
    proposal: IApiLink;
    proposals: IApiLink;
    site_visits: IApiLink;
    user_invites: IApiLink;
    self: IApiLink;
  };
}
export interface IRequestForProposal {
  attachmentReferences: IAttachmentReference[];
  bidderReview: IReview;
  categories: ICategory[];
  categoryIds: number[] | null;
  city: string | null;
  commentsCount: number | null;
  completedDate: string | null;
  conversationId: number | null;
  country: string | null;
  createdDate: string | null;
  date: string | null;
  deadlineDate: string | null;
  description: string | null;
  details: IRequestForProposalDetail[];
  id: number;
  includeMaterialsInQuote: boolean;
  lat: string | null;
  likesCount: number | null;
  lng: string | null;
  notes: string | null;
  postal: string | null;
  postedDate: string | null;
  privacy: string | null;
  proposal: IProposal | null;
  proposals: IProposal[] | null;
  province: string | null;
  questions: IRequestForProposalQuestion[] | null;
  rating: number | null;
  requesterName: string | null;
  requesterReview: IReview | null;
  size: string;
  state: string;
  street: string | null;
  street2: string | null;
  tags: string[] | null;
  templateId: string | null;
  title: string | null;
  type: string;
  uid: string;
  unread: any | null;
  user: any | null;
  buyNowPrice: number | null;
}
export interface ISiteVisitDetail extends ISiteVisit {
  contractor: IUser;
  homeowner: IUser;
  requestForProposal: IRequestForProposal;
  review: IReview;
  conversationId: number;
}

export interface ISiteVisitListResponse {
  _embedded: {
    models: ISiteVisit[];
  };
  _links: {
    first: IApiLink;
    self: IApiLink;
    next: IApiLink;
    last: IApiLink;
  };
  page: IPage;
}
