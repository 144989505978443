import {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Switch, Route, useHistory} from 'react-router-dom';
import {API_URL, SERVER_URL} from '../../constants';
import {
  resetSecuredFetchRequest,
  securedFetchRequest,
} from '@bidmii/common/lib/util/FetchRequest';
import ProtectedRoute from '../../common/ProtectedRoute';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
  userState,
  userStatsState,
  userProductsState,
  firestoreUserState,
  miscUserDetailsState,
  userLikesState,
  userDislikesState,
} from '../../recoil/user/userAtom';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import {apiRootState} from '../../recoil/rootAtom';
import {lastMsgState} from '../../recoil/socket/socketAtom';
import {authUserState} from '../../recoil/auth/AuthRecoil';
import useRecordSession from './hooks/useRecordSession';
import {leftMenuOpenState} from '../../recoil/navigation/NavigationRecoil';
import {
  filterValuesState,
  savedProjectsFilterState,
} from '../../recoil/filterValues/filterValuesAtom';
import {
  LazyBidmiiProLandingPage,
  LazyReviewWizard,
  LazySupport,
  LazyCategoriesList,
  LazyLocationsList,
  LazySupportPopup,
  LazyMain,
  LazyMainContainer,
  LazyMarketingPage,
  LazyProWebsite,
  LazyRequestForProposalsList,
  LazyLogin,
  LazyFormsRouter,
  LazyNewUserRouter,
} from '../../common/LazyLoader';
import {firebaseAuth, firestoreDb} from '../../App';
import {signOut} from 'firebase/auth';
import {collection, doc, getDoc, setDoc} from 'firebase/firestore';
import useRefreshUserState from '../../hooks/useRefreshUserState';
import useSnackbar from '../../hooks/useSnackbar';
import BidmiiCustomLoader from '../BidmiiCustomLoader/BidmiiCustomLoader';
import {getUserDataByLink} from './utils/getUserDataByLink';
import {loadingState} from '../../recoil/bidmiiCustomLoader/bidmiiCustomLoaderAtom';
import {isContractor, isHomeowner} from '../../util/UserUtils';
import useCelebrationLottie from '../../hooks/useCelebrationLottie';
import {ServiceOrderCheckout} from '../ServiceOrders/ServiceOrderCheckout';

const MainRouter = () => {
  const [user, setUser] = useRecoilState(userState);
  const lastMsg = useRecoilValue(lastMsgState);
  const history = useHistory();
  const setLoading = useSetRecoilState(loadingState);
  const setUserLikes = useSetRecoilState(userLikesState);
  const setUserDislikes = useSetRecoilState(userDislikesState);
  const setUserStats = useSetRecoilState(userStatsState);
  const setUserProducts = useSetRecoilState(userProductsState);
  const setMiscUserDetails = useSetRecoilState(miscUserDetailsState);
  const setAuthUser = useSetRecoilState(authUserState);
  const apiRoot = useRecoilValue(apiRootState);
  const recordSession = useRecordSession();
  const setLeftMenuOpen = useSetRecoilState(leftMenuOpenState);
  const setSavedProjectsFilter = useSetRecoilState(savedProjectsFilterState);
  const [firestoreUser, setFirestoreUser] = useRecoilState(firestoreUserState);
  const setFilterValues = useSetRecoilState(filterValuesState);
  const refreshUser = useRefreshUserState();
  const {BidmiiSnackbar, openSnackbar} = useSnackbar();
  const [celebrationAnimation, startCelebration] = useCelebrationLottie({});

  useEffect(() => {
    try {
      const type = (lastMsg as $TSFixMe)?.type;
      const model = (lastMsg as $TSFixMe)?.model;

      if (type === 'user_banned') {
        signOut(firebaseAuth)
          .then(() => {
            sessionStorage.clear();
            localStorage.clear();
            // eslint-disable-next-line no-alert
            window.alert(
              'Your account has been temporarily disabled, please contact your administrator at 416 628 1553',
            );
            setUser(null as any);
            setSavedProjectsFilter(false);
            history.push('/login');
          })
          .catch((err) => {
            console.warn(err);
            Sentry.withScope(function (scope) {
              scope.setTag('section', 'MainRouter.tsx useEffect()');
              Sentry.captureException(err);
            });
          });
      } else if (model === 'User' && type === 'update') {
        refreshUser();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [lastMsg, history, setUser, setSavedProjectsFilter, refreshUser]);

  useEffect(() => {
    if (apiRoot != null) {
      firebaseAuth.onAuthStateChanged((authUser) => {
        handleUserStateChange(authUser);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRoot]);

  useEffect(() => {
    // do not bother redirecting if user is on login page or in new user flow
    if (
      user?.uid &&
      window.location.pathname !== '/login' &&
      !window.location.pathname.includes('/new/user')
    ) {
      // if there is no user type and user is not already in signup flow, redirect to user type choice step
      if (!user?.userType && !localStorage.getItem('userType')) {
        history.push('/new/user/signup/user-type');
        return;
      }

      if (sessionStorage.getItem('isNewUser')) {
        if (
          isHomeowner(user) ||
          localStorage.getItem('anonymousProjectCreated') !== null
        ) {
          history.push('/new/user/signup/homeowner/1');
        } else if (isContractor(user)) {
          history.push(
            '/new/user/application/contractor/1?newApplication=true',
          );
        } else {
          history.push('/new/user/signup/user-type');
        }
        return;
      }

      if (firestoreUser && firestoreUser.newUserWizardStep) {
        process.env.NODE_ENV === 'development' &&
          console.log(
            'newUserWizardStep :>> ',
            firestoreUser.newUserWizardStep,
          );
        user?.userType === 'BIDDER'
          ? history.push(
              `/new/user/application/contractor/${firestoreUser.newUserWizardStep}`,
            )
          : history.push(
              `/new/user/signup/homeowner/${firestoreUser.newUserWizardStep}`,
            );
        return;
      }
    }

    if (localStorage.getItem('bidmiiProUid')) {
      if (user?.userType === 'REQUESTER') {
        history.push(`${localStorage.getItem('bidmiiProUid')}?migrate=true`);
      } else {
        history.push('/new/user/signup/homeowner/1');
      }
      return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const redirect = urlSearchParams.get('redirect');
    if (
      sessionStorage.getItem('signInSuccessUrl') ||
      (redirect && !(window as $TSFixMe).OVERRIDE_USER_ID)
    ) {
      const signInSuccessUrl = sessionStorage.getItem('signInSuccessUrl');
      sessionStorage.removeItem('signInSuccessUrl');
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      history.push(redirect || signInSuccessUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const migrateAnonymousUser = (userObject: $TSFixMe) => {
    process.env.NODE_ENV === 'development' &&
      console.log('MIGRATING ANONYMOUS USER');
    securedFetchRequest(`${API_URL}/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userType: localStorage.getItem('anonymousProjectCreated')
          ? 'REQUESTER'
          : 'BIDDER',
        uid: localStorage.getItem('anonymousUid'),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (localStorage.getItem('anonymousUid')) {
          localStorage.removeItem('anonymousUid');
          localStorage.removeItem('isAnonymous');
          localStorage.removeItem('anonymousProjectCreated');
          setUser({...userObject, ...res});
        }
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' && console.error(err);

        Sentry.withScope(function (scope) {
          scope.setUser({
            uid: user?.uid,
            firstName: user?.firstName,
          });
          scope.setTag('section', 'create_user_step_2');
          Sentry.captureException(err);
        });
      });
  };

  const handleUserStateChange = (authUser: $TSFixMe) => {
    setAuthUser(authUser);
    resetSecuredFetchRequest(
      authUser ? (forceRefresh) => authUser.getIdToken(forceRefresh) : null,
    );

    if (authUser) {
      if (authUser?.displayName) {
        const firstName = authUser.displayName.split(' ')[0];
        const lastName =
          authUser.displayName.split(' ').length > 1
            ? authUser.displayName.split(' ')[1]
            : '';
        sessionStorage.setItem('firstName', firstName);
        sessionStorage.setItem('lastName', lastName);
      }

      if (authUser.email) {
        sessionStorage.setItem('email', authUser.email);
      }

      authUser.getIdToken().then((token: $TSFixMe) => {
        const tokenExpiry = new Date();
        tokenExpiry.setHours(tokenExpiry.getHours() + 1);
        sessionStorage.setItem('Firebase-Authorization', token);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
        sessionStorage.setItem('Token-Expiry', tokenExpiry);
        sessionStorage.setItem('uid', authUser.uid);
        //postSessionData();

        // @ts-ignore
        const userDocRef = doc(collection(firestoreDb, 'users'), authUser.uid);

        getDoc(userDocRef)
          .then((doc) => {
            if (doc.exists()) {
              const data = doc.data();
              setFirestoreUser(data);
              if (data?.filters) {
                setFilterValues(data.filters);
              }
            } else {
              setDoc(userDocRef, {email: authUser.email}, {merge: true});
              setFirestoreUser({email: authUser.email});
              process.env.NODE_ENV === 'development' &&
                console.log('Firestore user doc does not exist');
            }
          })
          .catch((err: any) => {
            process.env.NODE_ENV === 'development' && console.error(err);
            Sentry.withScope(function (scope) {
              scope.setTag('section', 'MainRouter.tsx handleUserStateChange()');
              Sentry.captureException(err);
            });
          });

        if (!authUser.isAnonymous) {
          securedFetchRequest(
            `${
              // @ts-ignore
              apiRoot._links.currentUser.href
            }?view=lite&code=${authUser.uid.substr(0, 10)}`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
              priority: 'high',
            },
          )
            .then((userRes) => userRes.json())
            .then((userRes) => {
              setLoading(false);
              setUser(userRes);
              localStorage.setItem('user', JSON.stringify(userRes));

              if (
                process.env.NODE_ENV !== 'development' && // JK: Disable log rocket while in development
                process.env.REACT_APP_ENABLE_LOG_ROCKET === '1'
              ) {
                LogRocket.getSessionURL(function (sessionURL) {
                  recordSession(userRes, sessionURL);
                });

                LogRocket.identify(userRes.id, {
                  name: userRes.name,
                  email: userRes.email,
                  userType: userRes.userType,
                });
              }

              if (window.outerWidth <= 600) {
                setLeftMenuOpen(false);
              }

              if (localStorage.getItem('anonymousUid')) {
                migrateAnonymousUser(userRes);
                return;
              }

              if (userRes._links && !sessionStorage.getItem('isNewUser')) {
                getAdditionalUserData(userRes._links);
              }
            });
        }
      });
    } else {
      setLoading(false);
    }
  };

  const getAdditionalUserData = async (links: any) => {
    const currentUser = await getUserDataByLink(
      // @ts-ignore
      apiRoot?._links.currentUser.href,
    );
    currentUser.initialised = true;
    setUser(currentUser);

    const stats = await getUserDataByLink(links.stats.href);
    setUserStats(stats);

    const products = await getUserDataByLink(
      links.products.href.replace('{&recId,recModel}', ''),
    );
    if (products._embedded) {
      setUserProducts(products._embedded.models);
    }

    const likes = await getUserDataByLink(links.likes.href);
    setUserLikes(new Set(likes));

    if (user?.userType === 'BIDDER') {
      const dislikes = await getUserDataByLink(`${API_URL}/users/dislikes`);
      setUserDislikes(new Set(dislikes));
    }

    const miscDetails = await getUserDataByLink(`${API_URL}/user/user-details`);
    if (miscDetails._embedded) {
      setMiscUserDetails(miscDetails._embedded.models);
    }

    paymentSuccessFeedback();
  };

  const paymentSuccessFeedback = () => {
    const successMessages = {
      bidmii_pro: 'You have successfully subscribed to Bidmii Pro!',
      project_unlock: 'This project has been successfully unlocked!',
      project_promotion: 'Your project will be promoted for 7 days.',
      stage_funded: 'Stage funded! The contractor will be notified.',
      bid_range: 'Bid range successfully unlocked for this project.',
      id_verification: 'Verification payment successful',
    };

    const params = new URLSearchParams(window.location.search);
    const redirectStatus = params.get('redirect_status');
    const paymentType = params.get('payment_type');
    if (redirectStatus === 'succeeded' && paymentType) {
      // @ts-ignore
      openSnackbar(successMessages[paymentType]);
      startCelebration();
    }
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('Firebase-Authorization') &&
      window.location.pathname === '/incremental'
    ) {
      const params = new URLSearchParams(window.location.search);
      securedFetchRequest(`${API_URL}users/getGoogleAuthToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: params.get('code'),
        }),
      })
        .then(() => window.close())
        .catch((err) => {
          process.env.NODE_ENV === 'development' && console.error(err);
          Sentry.withScope(function (scope) {
            scope.setTag('section', 'MainRouter.tsx useEffect()');
            Sentry.captureException(err);
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, user]);

  const renderHomePage = (screenProps: $TSFixMe) => {
    if ((window as $TSFixMe).OVERRIDE_USER_ID) {
      return (
        <LazyMainContainer>
          <LazyProWebsite {...screenProps} />;
        </LazyMainContainer>
      );
    } else {
      if (!user || user.userType !== 'BIDDER') {
        return <LazyMarketingPage container />;
      } else if (user.userType === 'BIDDER') {
        return (
          <LazyMainContainer>
            <LazyRequestForProposalsList {...screenProps} type={'contractor'} />
          </LazyMainContainer>
        );
      }
    }
  };

  return (
    <>
      <CssBaseline />
      <Switch>
        {/* @ts-ignore */}
        <Route path="/new/user" component={LazyNewUserRouter} />
        {/* @ts-ignore */}
        <ProtectedRoute path="/review" component={<LazyReviewWizard />} />
        {/* This was meant to act as a bandaid solution for an error in share urls, remove ASAP pending review */}
        <Route
          path="/share"
          render={() => {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            const shareUrl = `${SERVER_URL.substring(
              0,
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              SERVER_URL.length - 1,
            )}${window.location.pathname}${window.location.search}`;
            window.location.href = shareUrl;
            return null;
          }}
        />
        <Route
          exact
          path="/bidmii-pro/canadian-financial"
          component={LazyBidmiiProLandingPage}
        />
        <Route exact path="/login" component={LazyLogin} />
        <Route exact path="/register" render={() => <LazyLogin register />} />
        {/* Used in mobile app WebViews */}
        <Route exact path="/support" component={LazySupport} />
        <Route exact path="/locations/cities" component={LazyLocationsList} />
        <Route
          exact
          path="/locations/cities/:province/:city"
          component={LazyCategoriesList}
        />
        <Route path="/m" render={() => <LazyMarketingPage container />} />
        <Route path="/forms" component={LazyFormsRouter} />
        <Route
          path="/service-orders/:uid/checkout"
          component={ServiceOrderCheckout}
        />
        <Route exact path="/" render={renderHomePage} />
        <Route path="/" component={LazyMain} />
      </Switch>
      {window.outerWidth > 600 && !(window as $TSFixMe).OVERRIDE_USER_ID && (
        <LazySupportPopup />
      )}
      <BidmiiSnackbar />
      <BidmiiCustomLoader />
      {celebrationAnimation}
    </>
  );
};

export default MainRouter;
