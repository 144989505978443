import {securedFetchRequest} from '@bidmii/common/lib/util/FetchRequest';
import * as Sentry from '@sentry/react';

export const getUserDataByLink = (link: string) => {
  return securedFetchRequest(link, {
    headers: {
      'Content-Type': 'application/json',
    },
    priority: 'high',
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      Sentry.withScope(function (scope) {
        scope.setTag('section', 'getUserDataByLink');
        Sentry.captureException(err);
      });
    });
};
